<script setup>
import { reactive, defineProps } from 'vue'

const props = defineProps(
    ['region', 'pref', 'region2pref', 'action', 'token', 'suffix']
)

const region = props.region;
const pref = props.pref;
const region2Pref = props.region2pref;
const action = props.action;
const suffix = props.suffix ? props.suffix : '';

const list = reactive({
    pref: {}
});

const setPrefList = (event) => {
    list.pref = {};
    region2Pref[event.target.value].map((key) => {
        list.pref[key] = pref[key];
    });
}

const execSearch = (event) => {
    event.target.closest('form').submit();
}
</script>

<template>
    <div class="c-search">
        <div class="c-search__inner">
            <form :action=action>
                <h3 class="c-search__heading">
                    ママとこどものはいしゃさん<br class="u-sp_min_none">グループ院の検索はこちらから
                </h3>
                <div class="c-search__current c-btn">
                    <a href="/map/">
                        <span>現在地から探す</span>
                    </a>
                </div>
                <div class="c-search__area c-btn--white --bottom">
                    <select :id="'region' + suffix" name="region" @change="setPrefList" aria-label="エリアから探す">
                        <option value="">エリアから探す</option>
                        <option v-for="(data, id) in region" :value="id">{{ data }}</option>
                    </select>
                </div>
                <div v-show="Object.keys(list.pref).length != 0" class="c-search__locale">
                    <div class="c-search__locale__inner">
                        <label v-for="(data, id) in list.pref" @click="execSearch">
                            <input :id="'pref' + suffix" type="radio" name="pref" :value="id">
                            <span>{{ data }}</span>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
